import $ from 'jquery';

// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
//* modal *//
// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝

$(function () {
  $('.js-modal-open').each(function () {
    $(this).on('click', function () {
      var target = $(this).data('target');
      var modal = document.getElementById(target);
      $(modal).fadeIn();
      return false;
    });
  });
  $('.js-modal-close').on('click', function () {
    $('.js-modal').fadeOut();
    return false;
  });
});

$(function () {
  //「開く」がクリックされた場合
  $('.js-modal-open').on('click', function () {
    $('body').css('overflow-y', 'hidden');  // 本文の縦スクロールを無効
  });
  //「閉じる」がクリックされた場合
  $('.js-modal-close').on('click', function () {
    $('body').css('overflow-y', 'auto');     // 本文の縦スクロールを有効
  });
});

function changeKeywordSubmitBtn() {
  // キーワード検索
  var keyword = $("#keyword").val();

  if (keyword) {
    // キーワードが入力された場合、「検索する」ボタン活性
    $("#keyword_submit").prop('disabled', false);
  } else {
    // キーワードが未入力の場合、「検索する」ボタン非活性
    $("#keyword_submit").prop('disabled', true);
  }

};

$(function () {
  // 読み込み時
  changeKeywordSubmitBtn();

  // キーワード更新時
  $("#keyword").change(function () {
    changeKeywordSubmitBtn();
  });
});
