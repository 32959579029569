import $ from 'jquery';
window.$ = $;

import './bootstrap';
import './main';
import './my-slick';

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();
